<template>
  <a-spin :spinning="loading">
    <div>
      <div class="font-size-36 font-weight-bold text-dark mb-4">Profile</div>

      <div>
        <a-form class="mb-4" :form="form" @submit="handleSubmit">
          <a-row justify="space-between">
            <a-col :span="6">
              <a-form-item id="name" label="First name">
                <a-input
                  size="large"
                  v-decorator="[
                    'first_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid Fist name',
                        },
                      ],
                    },
                  ]"
                  placeholder="First name"
                />
              </a-form-item>
            </a-col>

            <a-col :span="6" style="margin-left: 20px">
              <a-form-item id="name" label="Last name">
                <a-input
                  size="large"
                  v-decorator="[
                    'last_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid Last name',
                        },
                      ],
                    },
                  ]"
                  placeholder="Last name"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="6">
              <a-form-item label="Phone Number">
                <a-input
                  size="large"
                  :disabled="true"
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid Phone Number',
                        },
                      ],
                    },
                  ]"
                  placeholder="Phone Number"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <!-- SUBMIT BTN -->
          <a-button
            type="primary"
            htmlType="submit"
            size="large"
            class="text-center"
            :loading="loading"
          >
            <strong>Update Profile</strong>
          </a-button>
        </a-form>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapState } from "vuex";
import AddressMixin from "@/mixins/address";
import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [AddressMixin, ProfileMixin],
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: "profile" }),
    };
  },
  computed: {
    ...mapState("auth", ["authorized", "user"]),
  },
  mounted() {
    try {
      this.loading = true;

      this.form.setFieldsValue({
        first_name: this.getValueFromSource(this.user, "first_name"),
        last_name: this.getValueFromSource(this.user, "last_name"),
        phone: this.getValueFromSource(this.user, "phone"),
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.updateProfileHandler(values);
        }
      });
    },
    async updateProfileHandler({ first_name, last_name }) {
      try {
        this.loading = true;

        await this.updateProfile({ user: { first_name, last_name } });

        this.$notification.success({
          message: "Profile updated successfully",
        });
      } catch (error) {
        this.$notification.error({ message: "Error saving profile" });
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
