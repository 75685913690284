var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',[_c('div',{staticClass:"font-size-36 font-weight-bold text-dark mb-4"},[_vm._v("Profile")]),_c('div',[_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"justify":"space-between"}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"id":"name","label":"First name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'first_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a valid Fist name',
                      },
                    ],
                  },
                ]),expression:"[\n                  'first_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter a valid Fist name',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","placeholder":"First name"}})],1)],1),_c('a-col',{staticStyle:{"margin-left":"20px"},attrs:{"span":6}},[_c('a-form-item',{attrs:{"id":"name","label":"Last name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'last_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a valid Last name',
                      },
                    ],
                  },
                ]),expression:"[\n                  'last_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter a valid Last name',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","placeholder":"Last name"}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a valid Phone Number',
                      },
                    ],
                  },
                ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter a valid Phone Number',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","disabled":true,"placeholder":"Phone Number"}})],1)],1)],1),_c('a-button',{staticClass:"text-center",attrs:{"type":"primary","htmlType":"submit","size":"large","loading":_vm.loading}},[_c('strong',[_vm._v("Update Profile")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }